.body {
  margin: 0;
}

.center-max-width {
  max-width: 1200px;
  margin: auto;
}

.logo-font {
  font-family: monospace, "Lucida Console", "Courier New";
}

.shopify-svg {
  fill: #ffffff;
  width: 20px;
}

.youtube-svg {
  fill: #ffffff;
  width: 13px;
}

.nav-link {
  color: #4f5052;
  text-decoration: none;
}

.sample-creation-image {
  max-width: 800px;
}

.general-font {
  font-family: "SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.hero-text {
  font-size: 17px;
  font-weight: 400;
  color: #505256;
}

.btn-primary {
  background-color: #fddd41;
  color: #242222;
  border-color: #fddd41;
}

.btn-outline-secondary {
  background-color: #20a48d;
  color: #ffffff;
  border-color: #20a48d;
}

.btn-primary:hover {
  background-color: #242222;
  color: #fddd41;
  border-color: #242222;
}

.btn-outline-secondary:hover .shopify-svg {
  fill: #20a48d; 
}

.btn-outline-secondary:hover {
  background-color: #ffffff;
  color: #20a48d;
  border-color: #20a48d;
}

.btn-outline-secondary:hover .youtube-svg {
  fill: #20a48d; 
}

.sample-creation-col {
  max-width: 540px;
}

.sample-creation-img {
  min-width: 360px;
  max-width: 90%;
  box-shadow: 0 0 0 1px #20a48d, 6px 6px 0 0 #20a48d;
  transition: box-shadow 0.2s ease;
}

.sample-creation-img:hover {
  box-shadow: 0 0 0 1px #20a48d, 2px 2px 0 0 #20a48d;
}

.dashboard-img {
  box-shadow: 0 0 0 1px #20a48d, 6px 6px 0 0 #20a48d;
  transition: box-shadow 0.2s ease;
}

.dashboard-img:hover {
  box-shadow: 0 0 0 1px #20a48d, 2px 2px 0 0 #20a48d;
}

.header-icon {
  width: 20px;
}

.marquee {
  background-color: #fff;
  color: lightgrey;
  overflow: hidden;
}

.marquee__content {
  list-style: none;
  height: 100%;
  display: flex;
  animation: scrolling 60s linear infinite;
}

.marquee__content li {
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.marquee__content li img {
  max-width: 190px;
  max-height: 190px;
}

@keyframes scrolling{
0% { transform: translateX(-0vw); }
100% { transform: translateX(-160vw); }
}

.trust-box {
  padding: 6px;
  background: #fddd41;
  border-radius: 12px;
}

.star-icon {
  fill: #fddd41;
  width: 30px;
}

.navbar{
  background-color: #ffffff;
  margin: 0;
}

.features-icon {
  fill: #20a48d;
  max-width: 25px;
  max-height: 30px;
}

.feature-card {
  padding-top: 10px;
  border-style: dotted;
  border-width: 2px;
  border-color: #ffffff;
  border-radius: 12px;
}

.feature-card:hover {
  border-style: dotted;
  border-width: 2px;
  border-color: #fddd41;
  border-radius: 12px;
}

.dashboard {
  max-width: 680px;
}

.dashboard-image {
  border-radius: 10px;
  max-width: 100%;
  box-shadow: 0 0 0 1px #20a48d, 6px 6px 0 0 #20a48d;
  transition: box-shadow 0.2s ease;
}

.dashboard-image:hover {
  box-shadow: 0 0 0 1px #20a48d, 2px 2px 0 0 #20a48d;
}

.unlimited-icon {
  width: 14px;
}

.unlimited-box {
  padding: 6px 12px;
  border-radius: 18px;
  border-style: dotted;
  border-width: 1.5px;
}

.unlimited-box:hover {
  color: #20a48d;
  border-color: #20a48d;
}

.unlimited-box:hover .unlimited-icon {
  fill: #20a48d;
}

.logo-background {
  width: 90px;
  height: 90px;
  border-radius: 12px;
}

.btn-secondary {
  width: 100%;
}

.button-open-app {
  border-radius: 20px;
  color: #ffffff;
  background-color: #242222;
}

.button-open-app:hover {
  border-radius: 20px;
  color: #ffffff;
  background-color: #242222;
}

.shopify-svg-open-app {
  fill: #ffffff;
  width: 20px;
}

.btn-secondary:hover {
  background-color: #242222;
}

.star-icon-large {
  width: 25px;
}

.star-icon-small {
  width: 15px;
}

.thick-line {
  width: 260px;
  border-radius: 4px;
  border: none;
  border-top: 5px solid black; /* Adjust thickness as needed */
  height: 0;
}

.thick-line-filled {
  color: #000000;
  width: 260px;
  border-radius: 4px;
  border-top: 5px solid black; /* Adjust thickness as needed */
  height: 0;
  border-color: #000000;
  opacity: 1;
}

.link-review {
  color: black;
}

.link-review:hover {
 text-decoration: none;
}

.full-width {
  max-width: 500px;
}

.full-width-right {
  max-width: 650px;
}

.open-app-card {
  border-radius: 10px;
  box-shadow: 0 0 0 1px #20a48d, 6px 6px 0 0 #20a48d;
  transition: box-shadow 0.2s ease;
}

.open-app-card:hover {
  box-shadow: 0 0 0 1px #20a48d, 2px 2px 0 0 #20a48d;
  cursor: pointer;
}

.card-box {
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #20a48d, 6px 6px 0 0 #20a48d;
}

.blog-card {
  margin: 20px;
  height: 440px;
  width: 320px;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #20a48d, 6px 6px 0 0 #20a48d;
  transition: box-shadow 0.2s ease;
  cursor: pointer;
}

.blog-card:hover {
  box-shadow: 0 0 0 1px #20a48d, 2px 2px 0 0 #20a48d;
}

.blog-image {
  width: 320px;
}

.company-logo {
  max-width: 200px;
}